<template>
<v-main class="pt-0">
    <dashboard-core-app-bar />
    <router-view />

    <dashboard-core-footer />
</v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreAppBar: () => import('../core/AppBar.vue'),
      DashboardCoreFooter: () => import('../../Footer_backup.vue'),
    },
  }
</script>
